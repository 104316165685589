import React from 'react'
import Layout from '../components/Layout'
// import PaddedLayout from '../components/PaddedLayout'
import MachineNavigation from './MachineNavigation'

export default function ProductSpecifications(props) {
    let machine = props.pageContext
    return (
        <Layout>
            <MachineNavigation machine={machine} page="specifications"/>
            <div className="machine-specification-header">
        <h4>{machine.model} Specifications</h4>
        </div>
    <div className="machine-specifications">
    <h4>Body Contruction</h4>
      <div className="specificaton-row-odd">
        <div className="spec-name">Tank Material</div>
        <div className="spec-desc">{machine.tankMaterial}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Front Casters</div>
      <div className="spec-desc">{machine.frontCasters}</div>
      </div>
      <div className="specificaton-row-odd">
        <div className="spec-name">Rear Wheels</div>
        <div className="spec-desc">{machine.rearWheels}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Size</div>
      <div className="spec-desc">{machine.size}</div>
      </div>
      <div className="specificaton-row-odd">
        <div className="spec-name">Weight w/ Battery</div>
        <div className="spec-desc">{machine.withBatteryWeight}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Weight w/o Battery</div>
      <div className="spec-desc">{machine.noBatteryWeight}</div>
      </div>
      <h4>Brush / Pad System</h4>
      <div className="specificaton-row-odd">
        <div className="spec-name">Disk Motor</div>
        <div className="spec-desc">{machine.diskMotor}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Disk Brush Speed</div>
      <div className="spec-desc">{machine.size}</div>
      </div>
      <div className="specificaton-row-odd">
        <div className="spec-name">Rear Wheel</div>
        <div className="spec-desc">{machine.diskBrushSpeed}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Optional Disk Motor</div>
      <div className="spec-desc">{machine.optionalDiskMotor}</div>
      </div>
      <div className="specificaton-row-odd">
        <div className="spec-name">Brush Down Pressure</div>
        <div className="spec-desc">{machine.brushDownPressure}</div>
      </div>
      <h4>Power System</h4>
      <div className="specification-row-even">
        <div className="spec-name">System Vlotage</div>
      <div className="spec-desc">{machine.systemVoltage}</div>
      </div>
      <div className="specificaton-row-odd">
        <div className="spec-name">Standard Battery</div>
        <div className="spec-desc">{machine.standardBattery}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Optional Battery</div>
      <div className="spec-desc">{machine.optionalBattery}</div>
      </div>
      <div className="specificaton-row-odd">
        <div className="spec-name">Run Time</div>
        <div className="spec-desc">{machine.runTime}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Charger</div>
      <div className="spec-desc">{machine.charger}</div>
      </div>
      <h4>Drive System</h4>
      <div className="specificaton-row-odd">
        <div className="spec-name">Power</div>
        <div className="spec-desc">{machine.power}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Forward Speed</div>
      <div className="spec-desc">{machine.forwardSpeed}</div>
      </div>      <div className="specificaton-row-odd">
        <div className="spec-name">Scrubbing Speed</div>
        <div className="spec-desc">{machine.scrubbingSpeed}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Reverse Speed</div>
      <div className="spec-desc">{machine.reverseSpeed}</div>
      </div>    
      <h4>Solution / Recovery System</h4>
        <div className="specificaton-row-odd">
        <div className="spec-name">Solution Tank Capacity</div>
        <div className="spec-desc">{machine.solutionTankCapacity}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Charger</div>
      <div className="spec-desc">{machine.charger}</div>
      </div>   
         <div className="specificaton-row-odd">
        <div className="spec-name">Solution Flow / Filter</div>
        <div className="spec-desc">{machine.solutionFlowFilter}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Recovery Tank Capacity</div>
      <div className="spec-desc">{machine.recoveryTankCapacity}</div>
      </div>      
      <div className="specificaton-row-odd">
        <div className="spec-name">Demisting Chamber</div>
        <div className="spec-desc">{machine.demistingChamber}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Drain Saver</div>
      <div className="spec-desc">{machine.drainSaver}</div>
      </div>    
        <div className="specificaton-row-odd">
        <div className="spec-name">Vacuum Power</div>
        <div className="spec-desc">{machine.vacuumPower}</div>
      </div>
      <div className="specification-row-even">
      <div className="spec-name">Squeegee</div>
      <div className="spec-desc">{machine.squeegee}</div>
      </div>   
    </div>
        </Layout>
    )
}
